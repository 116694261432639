<script setup lang="ts">
import { onUnmounted, computed, watch } from 'vue';

defineOptions({
  name: 'Ui2Toast',
});
const emit = defineEmits(['update:modelValue']);

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
  width: {
    type: String,
    default: '400px',
  },
  showCloseButton: {
    type: Boolean,
    default: false,
  },
  autoCloseDelay: {
    type: Number,
    default: 0,
  },
  zIndex: {
    type: Number,
    default: 3,
  },
  attachTo: {
    type: String,
    default: 'body',
  },
});

const isVisibleRef = computed(() => props.modelValue);
const transitionAnimation = 'ui-toast-slide-left';
let timer: any;

function handleCloseOverlay() {
  emit('update:modelValue', !isVisibleRef.value);
}

function resetTimer() {
  clearTimeout(timer);
}

watch(
  isVisibleRef,
  (isVisible) => {
    resetTimer();
    if (props.autoCloseDelay > 0 && isVisible) {
      timer = setTimeout(() => {
        emit('update:modelValue', false);
      }, props.autoCloseDelay * 1000);
    }
  },
  { immediate: true },
);

onUnmounted(resetTimer);
</script>

<template>
  <Teleport :to="attachTo">
    <Transition :name="transitionAnimation">
      <div
        v-if="isVisibleRef"
        v-bind="$attrs"
        :class="['ui-toast-body', `ui-toast-body--${transitionAnimation}`]"
        :aria-hidden="!isVisibleRef"
        role="dialog"
        aria-modal="true"
        tabindex="-1"
        :style="{
          '--width': width,
          '--zIndex': zIndex,
        }"
      >
        <div class="ui-toast-document">
          <Ui2Button
            v-if="showCloseButton"
            class="ui-toast-document__close"
            type="close"
            size="md"
            @click="handleCloseOverlay"
          />
          <div class="ui-toast-document__body">
            <div class="ui-toast-document__content">
              <slot />
            </div>
          </div>
        </div>
      </div>
    </Transition>
  </Teleport>
</template>

<style lang="scss">
.ui-toast-body {
  position: fixed;
  z-index: var(--zIndex);
  width: var(--width);
  max-width: calc(100% - $space-48);
  outline: 0;

  @include v2-apply-upto(mobile) {
    width: 100%;
    max-width: calc(100% - $space-16);
  }
}

.ui-toast-document {
  height: 100%;
  width: 100%;
  background: $color-surface-primary;
  border: 1px solid $color-border-secondary;
  box-shadow: $shadow-md;
  position: relative;
  border-radius: $border-radius-rounded-lg;
}

.ui-toast-body--ui-toast-slide-left {
  top: $space-24;
  right: $space-24;

  @include v2-apply-upto(mobile) {
    left: $space-8;
    right: $space-8;
    top: unset;
    bottom: $space-16;
    transform: translate(0);
  }
}

.ui-toast-document__close {
  position: absolute;
  right: $space-8;
  top: $space-8;
}

.ui-toast-document__body {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100vh;
}

.ui-toast-document__content {
  @include v2-text-style(sm);
  overscroll-behavior: contain;
  overflow-y: auto;
  padding: $space-16 $space-48 $space-16 $space-16;
  flex-grow: 1;
}

.ui-toast-slide-left-enter-active {
  transition: transform $animation-duration-x-long $animation-type-standard;
}

.ui-toast-slide-left-leave-active {
  transition: transform $animation-duration-long $animation-type-emphasized-accelerate;
}

.ui-toast-slide-left-enter-from,
.ui-toast-slide-left-leave-to {
  transform: translateX(100%);

  @include v2-apply-upto(mobile) {
    transform: translateY(100%);
  }
}
</style>
