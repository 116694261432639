<script setup lang="ts">
import { useAttrs, resolveComponent } from 'vue';

defineOptions({
  name: 'Ui2NuxtLink',
});

const attrs = useAttrs();
const cName = attrs['component-name'] || attrs.componentName;
// don't ask.. don't change!!
const componentToDisplay = cName === 'NuxtLink' ? resolveComponent('NuxtLink') : String(cName || 'div');
</script>

<template>
  <component :is="componentToDisplay" v-bind="attrs" no-prefetch>
    <slot />
  </component>
</template>
